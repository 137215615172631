<template>
  <div>
    <el-alert type="warning">
      <template #title>
        <div>
          <div>回首向来萧瑟处，也无风雨也无情</div>
        </div>
      </template>
    </el-alert>
    <el-divider></el-divider>
    <el-timeline>
      <el-timeline-item
        v-for="item in list"
        type="primary"
        placement="top"
        icon="el-icon-star-on"
        :timestamp="item.time"
        :key="item.content"
      >
        <el-card>
          <el-row>
            <el-col :span="18"
              ><h4>{{ item.title }}</h4></el-col
            >
            <el-col :span="6" style="text-align: right">
              <el-button
                v-show="!!item.link && !item.imgs"
                size="mini"
                type="primary"
                plain
                @click="handleGo(item.link)"
                >Go →</el-button
              >
            </el-col>
          </el-row>

          <div class="narration_box" v-html="item.content"></div>
          <div v-if="item.imgs" class="my_img_box">
            <img
              v-for="src in item.imgs"
              :src="src"
              alt="o(╥﹏╥)o"
              :key="src"
              srcset=""
            />
          </div>
        </el-card>
      </el-timeline-item>
      <!-- 相册 -->
      <Album ref="albumEle" :imgs="imgs" :time="time" />
      <!-- 鉴权 -->
      <authMy ref="authMyEle" @getdata="getList" />
    </el-timeline>
  </div>
</template>

<script>
import { onMounted } from "vue";
import getdaily from "./composition/my/getdaily";
import getalbum from "./composition/my/getalbum";
// 相册
import Album from "@/components/album";
// 鉴权
import authMy from "@/components/auth-my";

export default {
  name: "my",
  components: { Album, authMy },
  setup() {
    // 日常 信息
    const { list, authMyEle, getList } = getdaily();

    onMounted(() => {
      sessionStorage.getItem("yfkey") || sessionStorage.setItem("yfkey", "all");
      // 加载日常
      getList();
    });

    // 相册
    const { albumEle, imgs, time, handleGo } = getalbum();


    return { list, albumEle, authMyEle, getList, handleGo, imgs, time };
  },
};
</script>

<style>
.narration_box {
  line-height: 28px;
}
.my_img_box img {
  max-width: 265px;
}

.tipNotifi {
  width: 250px;
}
.tipNotifi .el-notification__icon {
  color: rgb(0, 132, 255);
}
.tipNotifi .el-notification__title {
  font-size: 14px;
}
</style>
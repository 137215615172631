<template>
  <div align="center">
    <div class="mb-2" style="font-size: 16px; font-weight: 900">
      <el-button
        size="mini"
        type="primary"
        @click="pre"
        v-show="i != 0"
        :disabled="!state"
        style="padding: 4px 10px; min-height: 22px"
      >
        <i class="el-icon-caret-left"></i>
      </el-button>
      （{{ i + 1 }}/{{ imgs.length }}）
      <el-button
        size="mini"
        type="primary"
        @click="next"
        :disabled="!state"
        style="margin-left: 0; padding: 4px 10px; min-height: 22px"
        v-show="i < imgs.length-1"
      >
        <i class="el-icon-caret-right"></i>
      </el-button>
    </div>
    <img
      ref="img"
      v-show="state"
      style="max-width: 100%"
      :src="imgs[i] + '?key=' + key + '&time=' + time"
      alt="怎么没有加载出来呢🧐"
      srcset=""
    />
    <div v-show="!state">
      <img style="width: 100%" :src="load" alt="加载中" />
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import load from "@/assets/img/load/loading.gif";
export default {
  props: ["imgs", "time"],
  setup() {
    const key = ref(sessionStorage.getItem("yfkey") || "all"); // 关键key
    let state = ref(false);

    let i = ref(0); //当前图片下标
    // 上一张
    const pre = () => {
      state.value = false;
      i.value--;
    };
    // 下一张
    const next = () => {
      state.value = false;
      i.value++;
    };

    let img = ref(); //img
    onMounted(() => {
      img.value.onload = () => {
        state.value = true;
      };
    });

    return { key, i, pre, next, img, load, state };
  },
};
</script>
<style>
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>


/**
 * @description: 日常
 */
import { ref } from "vue";
import { daily } from "@/api";
import { ElMessage, ElNotification } from 'element-plus'
import { loading } from '@/components/ui'
// 错误信息
const msg = [
    '好可惜！再试试？😒',
    "别闹！不要猜啦。😄",
    "这你都不知道吗。 (⊙_⊙)？",
    "哈哈哈，♪(^∇^*)"
]
// 列表
const list = ref([]);
// 鉴权
const authMyEle = ref(null);
// 加载数据
const getList = (flag) => {
    let load = loading()
    // 返回用于接下来处理
    return daily.list({}).then((res) => {
        const { data, code, level } = res.data;
        load.close();
        if (code === 0) {
            list.value = [...parsetData(data)];
            // 倒序提示

            ElNotification({
                title: "推荐倒序查看",
                customClass: "tipNotifi",
                iconClass: "el-icon-s-help",
                message: "点击我即可",
                duration: 5000,
                onClick: () => {
                    list.value = list.value.reverse();
                },
            });
            // 角色确认
            if (flag) {
                authMyEle.value.hide(level);
            }
        } else if (code == 1) {
            // 角色确认失败
            if (flag) {
                ElMessage(msg[Math.round(Math.random() * 3)])
            }
            // 恢复默认权限
            sessionStorage.setItem('yfkey', 'all')
        }
    }).catch(() => {
        load.close()
    });
};
// 数据处理
function parsetData(data) {
    return data.map(e => {
        if (e.imgs) {
            e.imgs = e.imgs.map(src => {
                return src + '?key=' + sessionStorage.getItem('yfkey') + '&time=' + e.link
            })
        }
        return e
    })
}
// 权限判断提示


export default () => {
    return {
        list,
        authMyEle,
        getList
    }
}

相册
<template>
  <el-dialog center width="80%" custom-class='my_dialog-img' v-model="visible" :close-on-click-modal='false' destroy-on-close>
    <!-- 轮播 -->
    <carousel :imgs="imgs" :time="time" />
  </el-dialog>
</template>

<script>
import { ref } from "vue";
import carousel from "./carousel.vue";
export default {
  components: { carousel },
  props: {
    imgs: {
      type: Array,
      default: () => {
        return [];
      },
    },
    time: {
      type: String,
    },
  },
  setup() {
    const visible = ref(false);
    const show = () => {
      visible.value = true;
    };
    return { visible, show };
  },
};
</script>

<style>
.my_dialog-img{
  max-width: 700px;
}
</style>
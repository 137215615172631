<template>
  <span class="auth_my">
    <a class="model_3d fixed_btn_class" @click="ck3d"> 3D </a>
    <a class="cai_dan fixed_btn_class" @click="show">
      <i class="el-icon-position"></i>
    </a>
    <el-dialog :width="350" v-model="visible">
      <!-- 标题 -->
      <template #title>
        <div class="auth_my_tit">
          <div class="auth_my_lbt">
            <div v-for="item in title" :key="item">
              {{ item }}
            </div>
          </div>
        </div>
      </template>
      <!-- 当前权限 -->
      <div class="mb-2" align="right" style="font-weight: 900; font-size: 14px">
        让你发现了 ：{{ state || "🎨🎨🎨" }}
      </div>

      <!-- 选项 -->
      <el-collapse model-value="" @change="handleChange" :accordion="true">
        <el-collapse-item title="⚽⚾🥎🏀" name="4">
          <div>
            <el-input
              size="small"
              v-model="val"
            >
              <template #append>
                <el-button
                  @click="validate"
                  class="level_btn"
                  size="small"
                  type="primary"
                  >验证</el-button
                >
              </template>
            </el-input>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-dialog>
  </span>
</template>

<script>
// 相册

import { ref } from "vue";
import { ElMessage } from "element-plus";
const titles = [
  "此情可待万追忆，只是当时已惘然",
  "无可奈何花落去，似曾相识燕归来",
  "白发催年老，青阳逼岁除",
  "流光容易把人抛，红了樱桃，绿了芭蕉",

  "沉舟侧畔千帆过，病树前头万木春",
  "道由白云尽，春与青溪长",
  "天生我材必有用，千金散尽还复来",
  "海阔凭鱼跃，天高任鸟飞",
];
export default {
  setup(props, { emit }) {
    // 折叠面板状态
    const state = ref("");
    // 显示与隐藏
    const visible = ref(false);
    const show = () => {
      visible.value = true;
    };
    const hide = (v) => {
      visible.value = false;
      if (v) {
        switch (v) {
          case "level1":
            state.value = "1级权限";
            break;
          case "level2":
            state.value = "2级权限";
            break;
          case "level3":
            state.value = "3级权限";
            break;
        }
      }
    };

    // 标题
    const title = ref(titles);
    // 验证
    const val = ref("");
    const validate = () => {
      sessionStorage.setItem("yfkey", val.value);
      emit("getdata", true);
    };
    // 切换
    const handleChange = () => {
      val.value = "";
    };
    // 3d
    const ck3d = () => {
      ElMessage.warning({
        dangerouslyUseHTMLString: true,
        message: `
                <div class='mb-2'><strong>🛠</strong></div>
                <div>施工中~~~</div>
              `,
      });
    };
    return {
      visible,
      show,
      hide,
      state,
      title,
      validate,
      val,
      handleChange,
      ck3d,
    };
  },
};
</script>

<style>
.fixed_btn_class {
  position: fixed;
  right: 15px;
  padding: 1px 3px;
  border-radius: 2px;
  color: #ffff;
  font-weight: 900;
  background: #409eff;
}
.model_3d {
  bottom: 15px;
}
.cai_dan {
  bottom: 44px;
}

/* 按钮 */
.level_btn {
  background: #409eff !important;
  color: #fff !important;
}
/* 标题 */
.auth_my .el-dialog__header {
  background-color: #2f94fc;
  padding: 15px 20px 10px;
  color: #fff;
}
.auth_my .el-dialog__headerbtn {
  top: 15px;
}
.auth_my .el-dialog__close {
  color: #fff !important;
}

/* 标题轮播 */
.auth_my_tit {
  height: 20px;
  overflow: hidden;
}
.auth_my_lbt {
  animation: authMy 25s infinite;
}
@keyframes authMy {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(-19x);
  }
  20% {
    transform: translateY(-38px);
  }
  30% {
    transform: translateY(-57px);
  }
  40% {
    transform: translateY(-76px);
  }
  50% {
    transform: translateY(-95px);
  }
  60% {
    transform: translateY(-114px);
  }
  70% {
    transform: translateY(-133px);
  }
}
</style>
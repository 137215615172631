/**
 * @description: 相册
 */
import { ref } from "vue";
import { daily } from "@/api";

import { loading } from '@/components/ui'
import { ElMessage } from 'element-plus'
// 相册
const albumEle = ref(null);
const imgs = ref([]);
const time = ref("");
const handleGo = (id) => {
    let load = loading()
    daily.getImg({ id }).then((res) => {
        const { data, code } = res.data;
        load.close();
        if (code === 0) {
            time.value = id;
            imgs.value = [...data];
            albumEle.value.show();
        }else{
            ElMessage('加载失败了，奇怪🧐？')
        }
    }).catch(() => {
        load.close()
    });
};
export default () => {
    return {
        albumEle,
        imgs,
        time,
        handleGo
    }
}